<template>
  <div>
    <div class="d-flex">
      <nav id="breadcrumbs" class="mb-3">
        <ul>
          <li>
            <router-link :to="{ name: 'company.dashboard' }">
              <i class="uil-home-alt"></i>
            </router-link>
          </li>
          <li>
            {{ $t('report.linkedin.report') }}
          </li>
        </ul>
      </nav>
    </div>

    <div class="section-header mb-lg-2 border-0 uk-flex-middle">
      <div class="section-header-left">
        <h2 class="uk-heading-line text-left"><span> {{ $t('report.linkedin.report') }}</span></h2>
      </div>
    </div>

    <!--End Modal Profile -->
    <div class="card">
      <!-- Card header -->
      <div class="card-header actions-toolbar border-0">
        <div class="d-flex justify-content-between align-items-center">
          <div class="row" style="width:100%">
            <div class=" col-md-1  search-icon ">
              <i class="uil-search"></i>
            </div>
            <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
              <input class="uk-search-input shadow-0 uk-form-small" type="search" v-model="filter.search"
                :placeholder="$t('general.search')" autofocus="" />
            </div>
          </div>

          <div class="d-flex">
            <a :uk-tooltip="$t('filters.order.sort')" aria-expanded="false" class="btn btn-icon btn-hover  btn-circle"
              href="javascript:void(0)" title="">
              <i class="uil-list-ui-alt"></i>
            </a>
            <div class="uk-dropdown" uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up">
              <ul class="uk-nav uk-dropdown-nav">
                <li class="uk-active"><a href="javascript:void(0)" @click="sortOrder('asc', 'name')">{{
                  $t('sorting.a_z')
                }}</a></li>
                <li><a href="javascript:void(0)" @click="sortOrder('desc', 'name')">{{ $t('sorting.z_a') }}</a></li>
                <li><a href="javascript:void(0)" @click="sortOrder('asc', 'id')"> {{ $t('sorting.created_at_old') }}</a>
                </li>
                <li><a href="javascript:void(0)" @click="sortOrder('desc', 'id')"> {{ $t('sorting.created_at_new')
                }}</a>
                </li>
              </ul>
            </div>

            <a class="btn btn-icon btn-hover  btn-circle" href="javascript:void(0)"
              :uk-tooltip="$t('general.filter_list')">
              <i class="uil-filter" />
            </a>
            <div class="large" id="filterDropdown"
              uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up">
              <div class="filter-container">
                <div class="uk-child-width-1-2@s uk-grid-small" uk-grid>
                  <div class="date-text-area">
                    <h5 class="uk-text-bold"> {{ $t('general.date') }}</h5>
                    <h5 class="uk-text-bold"> {{ $t('general.date_range') }} : {{ getDateFilterRange }}</h5>
                  </div>

                  <VueCtkDateTimePicker v-model="selectedDate" :label="$t('general.choose_date')" :format="'YYYY-MM-DD'"
                    :no-button-now="true" :no-shortcuts="true" only-date auto-close />
                </div>

                <div class="uk-flex uk-flex-right p-2">
                  <button class="btn btn-default grey" @click="filterResult"><i class="uil-filter"></i>
                    {{ $t('general.filter') }}
                  </button>
                </div>
              </div>
            </div>

            <a class="btn btn-icon btn-hover  btn-circle" href="javascript:;" :uk-tooltip="$t('filters.export_excell')"
              @click="exportUserContents">
              <i class="fa-file-excel fa"></i>
            </a>
          </div>
        </div>
      </div>
      <!-- Table -->
      <default-loading v-if="user_loading" />
      <template v-else>
        <div class="table-responsive">
          <table class="table align-items-center">
            <thead>
              <tr>
                <th scope="col">{{ $t('report.linkedin.userInfo') }}</th>
                <th scope="col">{{ $t('report.linkedin.mail') }}</th>
                <th scope="col">{{ $t('report.linkedin.content') }}</th>
                <th scope="col">{{ $t('report.linkedin.provider') }}</th>
                <th scope="col">{{ $t('report.linkedin.completionStatus') }}</th>
              </tr>
            </thead>
            <tbody class="list">
              <template v-for="item in items">
                <tr>
                  <td>{{ item.learner_name }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.content_title }}</td>
                  <td>{{ item.content_provider }}</td>
                  <td>{{ item.is_completed ? $t('general.yes') : $t('general.no') }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <pagination :pagination="pagination" class="mt-2 mb-3" @changePage="setPage($event)"></pagination>
      </template>
    </div>
  </div>
</template>

<script>
import reports, { ERROR, GET_ITEMS, ITEMS, LOADING, MODULE_NAME, SUCCESS } from "@/core/services/store/reports.module";

import store from '@/core/services'
import moment from "moment-timezone";
import axios from "axios";
import UserProfile from "@/view/company/User/UserProfile";
import UserFilter from "@/view/company/User/Filter";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import Multiselect from "vue-multiselect";
import Pagination from "@/assets/components/Pagination";
import { getSubDomainId } from "@/core/services/store.js";
const _MODULE_NAME = MODULE_NAME;

export default {
  name: "ReportUsers",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }

    registerStoreModule(_MODULE_NAME, reports)
  },
  components: { UserProfile, UserFilter, DefaultLoading, Multiselect, Pagination },
  data() {
    return {
      from_date: null,
      to_date: null,
      selectedDate: moment().format("YYYY-MM-DD"),
      dateFilterRange: null,
      pagination: null,
      content_ids: [],
      assignment_ids: [],
      items: [],
      activeItem: null,
      error: null,
      page: 1,
      per_page: 10,
      sort_by: "id",
      sort_desc: "desc",
      formattedItems: [],
      userList: [],
      assignmentList: [],
      filter: {
        search: null,
        order: "desc",
        sort: "id",
      },
      successMessage: "",
    }
  },
  computed: {
    getDateFilterRange() {
      return `${moment(this.selectedDate).format('DD.MM.YYYY')} - ${moment(this.selectedDate).add(6, 'days').format('DD.MM.YYYY')}`;
    },
    items: {
      get() {
        return store.getters[_MODULE_NAME + '/' + ITEMS]
      },
      set(value) {
      }
    },
    user_loading: {
      get() {
        return store.getters[_MODULE_NAME + '/' + LOADING]
      },
      set(value) {
      }
    },
    errors: {
      get() {
        return store.getters[_MODULE_NAME + '/' + ERROR]
      },
      set(value) {
      }
    },
    success() {
      return store.getters[_MODULE_NAME + '/' + SUCCESS]
    }
  },
  methods: {
    exportUserContents() {
      let contentIds = [];
      if (this.content_ids.length > 0) {
        this.content_ids.every((item) => {
          if (item.id === 'all') {
            contentIds = [];
            this.userList.forEach((user) => {
              if (user.id === 'all')
                return;
              contentIds.push(user.id);
            })
            return false
          } else {
            contentIds.push(item.id);
            return true;
          }
        });
      }
      let assignmentIds = [];
      if (this.assignment_ids.length > 0) {
        this.assignment_ids.every((item) => {
          if (item.id === 'all') {
            assignmentIds = [];
            return false
          } else {
            assignmentIds.push(item.id);
            return true;
          }
        });
      }
      axios.get(process.env.VUE_APP_BACKEND_APP_URL + '/api/report/export/user-contents', {
        responseType: 'blob',
        params: {
          content_ids: JSON.stringify(contentIds),
          assignment_ids: JSON.stringify(assignmentIds),
          sort: this.filter.sort,
          order: this.filter.order,
          like: this.filter.search,
          from_date: this.from_date,
          to_date: this.to_date,
          report: Math.random() * 9999999
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
          'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + this.secureLocalStorage("token"),
          Company: getSubDomainId?.value,
        }
      }).then((response) => {
        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        let day = moment().format('YYYYMMDDHHMMSS');
        let fileName = 'user_contents_report_list_' + day + '.xlsx';
        fileLink.setAttribute('download', fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      })
    },
    filterResult(filter = null) {
      UIkit.dropdown("#filterDropdown").hide();

      this.error = null;
      let filters = {
        page: this.page,
        start_date: this.selectedDate,
        day: 7,
      };

      console.log("filters", filters)
      this.from_date = filters['start_date'];
      this.to_date = filters['day'];
      store.dispatch(_MODULE_NAME + '/' + GET_ITEMS, {
        url: 'api/report/linkedin-learning',
        filters: filters
      }).then(response => {
        if (response.status) {
          this.items = response.data.data;
        }
      });
    },
    getContent() {
      let filters = {
        page: 1,
      };

      store.dispatch(_MODULE_NAME + '/' + GET_ITEMS, {
        url: 'api/report/linkedin-learning',
        filters: filters
      }).then(response => {
        if (response.status) {
          this.items = response.data.data
        }
      });
    },
    sortOrder(order, sort) {
      this.page = 1;
      this.filter.order = order;
      this.filter.sort = sort;


      this.filterResult();
    },
    setPage(page) {
      this.page = page;
      this.filterResult()
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    },
    getFilter(value) {
      this.page = 1;
      this.filterResult(value);
    },
  },
  mounted() {
    this.filterResult();
    this.getContent();
    this.debouncedFilterSearch = _.debounce(this.filterResult, 500)

    if (this.$route.params.succesMessage != null) {
      this.successMessage = this.$route.params.succesMessage;
      this.scrollToTop();
    }
  },
  watch: {
    success: function (val) {
      if (val) {
        this.filterResult()
      }
    },
    'filter.search': function (val) {
      this.debouncedFilterSearch()
    },
    formattedInstitution(value) {
      if (value) {
        setTimeout(() => {
          $('.selectpicker1').selectpicker();
        }, 200)
      }
    },
    formattedUserGroup(value) {
      if (value) {
        setTimeout(() => {
          $('.selectpicker2').selectpicker();
        }, 200)
      }
    },
    formattedPosition(value) {
      if (value) {
        setTimeout(() => {
          $('.selectpicker3').selectpicker("refresh").trigger('change');
        }, 200)
      }
    },
    formattedTitle(value) {
      if (value) {
        setTimeout(() => {
          $('.selectpicker4').selectpicker("refresh").trigger('change');
        }, 200)
      }
    },
    formattedRegion(value) {
      if (value) {
        setTimeout(() => {
          $('.selectpicker5').selectpicker("refresh").trigger('change');
        }, 200)
      }
    },
    formattedDepartment(value) {
      if (value) {
        setTimeout(() => {
          $('.selectpicker6').selectpicker("refresh").trigger('change');
        }, 200)
      }
    },
    formattedBranch(value) {
      if (value) {
        setTimeout(() => {
          $('.selectpicker7').selectpicker("refresh").trigger('change');
        }, 200)
      }
    },
    activeItem(value) {
      if (value) {
        this.getUserDetail(value.id);
      }
    }
  },
  beforeDestroy() {
    const modal = UIkit.modal("#modal-profile");
    if (modal) modal.$destroy(true);
  }

};
</script>

<style scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.uk-alert {
  margin: 0;
}

.date-text-area {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4px;
}

.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}
</style>
